import React, { useEffect, useState } from 'react';
import * as homePageStyles from './homePage.module.scss';
import CarouselHome from '../CarouselHome/carouselHome';
import Santa from '../../images/svg/santa_hat.svg';

const HomePage = () => {
  const [fadeIn, setFadeIn] = useState(false);

  // Trigger fade-in on component mount
  useEffect(() => {
    setTimeout(() => setFadeIn(true), 500); // Delay to make the fade-in more noticeable
  }, []);

  return (
    <>
      {/*  <div className={`container-fluid ${homePageStyles.homeAnnoucement}`}>
        <h3>
          Vielen Dank für Ihre Treue / Wir wünschen ein glückliches & gesundes
          2025!
        </h3>
        <img
          src={Santa}
          alt=''
          style={{ width: '70px', height: '70px', margin: '5px' }}
        />
         <div className={`row`}></div> 
      </div> */}
      <div className={`${homePageStyles.homeContainer}`}>
        <div className={`col-md-12 col-lg-7`}>
          <CarouselHome />
        </div>
        <div className={`col-md-12 col-lg-4 ${homePageStyles.rightBox}`}>
          <h1>
            Willkommen bei <br />
            <span className='yellow'>Bowman Automobile AG</span>
          </h1>
          <div className={`mt-2 mb-2`}>
            <div className={`${homePageStyles.buttonContainer}`}>
              <a href='https://bowman.sopl.ch/' target='_blank'>
                <button className='button-md align-self-center'>
                  Jetzt Termin vereinbaren
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
